/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icon-close-line.svg';
import { generateID } from 'common/utils';

const TermsModal = ({ onClose }) => {
  return (
    <div className="terms-overlay">
      <div className="terms-main">
        <CloseIcon
          id={generateID('btn-close', 'modal-terms')}
          className="terms-close"
          onClick={onClose}
        />
        <div className="terms-container">
          <h3 className="text-center">End User Terms</h3>

          <div className="tos-rt w-richtext">
            <p>
              <strong>Last Updated: </strong>June 5, 2024
            </p>
            <p>‍</p>
            <p>
              These Demand IQ End User Terms ("<strong>User Terms</strong>") is
              a binding agreement between you ("<strong>End User</strong>" or "
              <strong>you</strong>") and Demand IQ, Inc. ("
              <strong>Demand IQ</strong>", "<strong>we</strong>", "
              <strong>us</strong>", or "<strong>our</strong>"). These User Terms
              govern your use of the Demand IQ website application and mobile
              application (such mobile application, the “
              <strong>Application</strong>,” and together with the website
              application and all related documentation, the "
              <strong>Service</strong>"). The Service is licensed, not sold, to
              you.
            </p>
            <p>‍</p>
            <p>
              BY ACCESSING OR USING THE SERVICE, YOU (A) ACKNOWLEDGE THAT YOU
              HAVE READ AND UNDERSTAND THESE USER TERMS; (B) REPRESENT THAT YOU
              ARE OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT
              THESE USER TERMS AND AGREE THAT YOU ARE LEGALLY BOUND BY TO THESE
              USER TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE OR
              ACCESS THE SERVICE.PLEASE REVIEW THESE USER TERMS, AS THEY AFFECT
              YOUR RIGHTS REGARDING YOUR USE OF THE SERVICES AND YOUR ABILITY TO
              BRING CLAIMS AGAINST DEMAND IQ.
            </p>
            <p>‍</p>
            <ol role="list">
              <li>
                <strong>
                  Service Use; Content. The Service provides you with access to
                  resources, tools,
                </strong>{' '}
                products, services, and related features, functionality, and
                content (collectively, "<strong>Content</strong>"), some of
                which may be accessible on, offered through, or hosted on Demand
                IQ's or its customer's websites. The Content may display,
                include, or make available third-party content (including data,
                information, applications, and other products, services, and/or
                materials) or provide links to third-party websites or services,
                including through third-party advertising. Your access to and
                use of the Service and all related Content is at your own risk.
                All Content are provided to you without any warranty or
                guarantee of their accuracy, completeness, timeliness, validity,
                compliance, legality, decency, quality, or any other aspect
                thereof. Demand IQ does not assume and will not have any
                liability or responsibility to you or any other person or entity
                for your use of the Service and its Content. All third-party
                materials, services and links thereto contained within the
                Service are provided solely as a convenience to you, and you
                access and use them entirely at your own risk and subject to
                such third parties' terms and conditions..
              </li>
            </ol>
            <ol start="2" role="list">
              <li>
                <strong>Privacy</strong>. Demand IQ may make the Service
                available on the website of a Demand IQ customer, in which case
                that customer, and not Demand IQ, will be the controller of any
                personal information that you provide through the Service. If
                you have any questions regarding how your personal information
                may be processed or the rights you may have regarding your
                personal information, we encourage you to review the privacy
                policy set forth on the website where you access the Service. If
                you would like more information about Demand IQ's own
                information processing and privacy practices, you may access the
                Demand IQ Privacy Policy at
                https://demand-iq.com/privacy-policy/. All information we
                collect through or in connection with the Service is subject to
                our Privacy Policy.
              </li>
            </ol>
            <ol start="3" role="list">
              <li>
                <strong>Use Rights</strong>. Subject to these User Terms, Demand
                IQ grants you a limited, non-exclusive, and nontransferable
                right to, during the term of these User Terms and strictly in
                accordance with these User Terms, (a) access and use the Service
                for your personal use; and (b) access and, to the extent
                applicable, download the Content made available in or otherwise
                accessible through the Service.
              </li>
            </ol>
            <ol start="4" role="list">
              <li>
                <strong>Restrictions on Use</strong>. You shall not use the
                Service for any purposes beyond the scope of the access granted
                in these User Terms. Any uses of the Service not expressly
                permitted under the terms of these User Terms is expressly
                prohibited. Without limiting the generality of the foregoing,
                you shall not: (a) copy the Service; (b) modify, translate,
                adapt, or otherwise create derivative works or improvements,
                whether or not patentable, of the Service; (c) reverse engineer,
                disassemble, decompile, decode, or otherwise attempt to derive
                or gain access to the source code of the Service or any part
                thereof; (d) remove, delete, alter, or obscure any trademarks or
                any copyright, trademark, patent, or other intellectual property
                or proprietary rights notices from the Service, including any
                copy thereof; (d) rent, lease, lend, sell, sublicense, assign,
                distribute, publish, transfer, or otherwise make available the
                Service, or any features or functionality of the Service, to any
                third party for any reason, including by making the Service
                available on a network where it is capable of being accessed by
                more than one device at any time; or (e) remove, disable,
                circumvent, or otherwise create or implement any workaround to
                any copy protection, rights management, or security features in
                or protecting the Service.
              </li>
            </ol>
            <ol start="5" role="list">
              <li>
                <strong>Right to Provide Data</strong>. You represent and
                warrant to Demand IQ that you have the right to provide and use
                all information and data entered into or accessed through the
                Service. You are responsible for any unauthorized creation,
                collection, receipt, transmission, access, storage, disposal,
                use, or disclosure of personal information and other
                information, content, and data under you control or in your
                possession. Such information and data may constitute personal
                information or other identifying information of individuals. YOU
                ARE SOLELY RESPONSIBLE FOR OBTAINING ALL RELEVANT PERMISSIONS
                FROM SUCH INDIVIDUALS TO SUBMIT SUCH DATA TO THE SERVICES.
                DEMAND IQ WILL HAVE NO LIABILITY WHATSOEVER RESPECTING ANY CLAIM
                BY ANY THIRD PARTY ALLEGING THAT YOU HAVE SUBMITTED ANY OF THEIR
                DATA, OR THAT DEMAND IQ HAS PROCESSED YOUR DATA IN ACCORDANCE
                WITH THIS AGREEMENT, WITHOUT SUCH THIRD PARTY’S CONSENT, IN
                VIOLATION OF THEIR PRIVACY OR OTHER PROPRIETARY RIGHTS OR IN
                VIOLATION OF ANY APPLICABLE DATA AND PRIVACY LAWS.
              </li>
            </ol>
            <ol start="6" role="list">
              <li>
                <strong>Reservation of Rights</strong>. You acknowledge and
                agree that the Service is provided under license, and not sold,
                to you. You do not acquire any ownership interest in the Service
                or any other rights thereto under these User Terms other than to
                use the Service in accordance with the limited rights granted
                herein, and subject to all terms, conditions, and restrictions
                hereunder. Demand IQ and its customers, partners, licensors and
                service providers reserve and shall retain their entire right,
                title, and interest in and to the Service, including all
                copyrights, trademarks, and other intellectual property rights
                therein or relating thereto, except as expressly granted to you
                in these User Terms.
              </li>
            </ol>
            <ol start="7" role="list">
              <li>
                <strong>Geographic Restrictions</strong>. You acknowledge that
                you may not be able to access all or some of the Content outside
                of the United States and that access thereto may not be legal by
                certain persons or in certain countries. If you access the
                Content from outside the United States, you are responsible for
                compliance with local laws.
              </li>
            </ol>
            <ol start="8" role="list">
              <li>
                <strong>Updates</strong>. Demand IQ may from time to time in its
                sole discretion develop and provide Service updates, which may
                include upgrades, bug fixes, patches, other error corrections,
                and/or new features (collectively, including related
                documentation, "<strong>Updates</strong>"). Updates may also
                modify or delete in their entirety certain features and
                functionality. You agree that Demand IQ has no obligation to
                provide any Updates or to continue to provide or enable any
                particular features or functionality. You further agree that all
                Updates will be deemed part of the Service and be subject to all
                terms and conditions of these User Terms.
              </li>
            </ol>
            <ol start="9" role="list">
              <li>
                <strong>Term and Termination</strong>. These User Terms commence
                when you download, install, access, or otherwise use the Service
                and will continue in effect until terminated by you or Demand IQ
                as set forth in this Section. You may terminate these User Terms
                by discontinuing your use of the Service and deleting all
                Content that you may have downloaded. Demand IQ may terminate
                these User Terms at any time without notice, which Demand IQ may
                do in its sole discretion. In addition, these User Terms will
                terminate immediately and automatically without any notice if
                you violate any of the terms and conditions of these User Terms.
                Upon any such termination, all rights granted to you under these
                User Terms will also terminate; and you must cease all use of
                the Service. Termination will not limit any of Demand IQ's
                rights or remedies at law or in equity.
              </li>
            </ol>
            <ol start="10" role="list">
              <li>
                <strong>Disclaimer of Warranties</strong>. THE SERVICE AND ALL
                CONTENT IS PROVIDED TO YOU "AS IS" AND WITH ALL FAULTS AND
                DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT
                PERMITTED UNDER APPLICABLE LAW, DEMAND IQ, ON ITS OWN BEHALF AND
                ON BEHALF OF ITS AFFILIATES, CUSTOMERS, AND ITS AND THEIR
                RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS
                ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
                OTHERWISE, WITH RESPECT TO THE SERVICE AND ALL CONTENT,
                INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND
                WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
                PERFORMANCE, USAGE, OR TRADE PRACTICE. DEMAND IQ ASSUMES NO
                RESPONSIBILITY FOR YOUR RELIANCE ON THE SERVICE, OR FOR ANY
                ERRORS, OMISSIONS OR INACCURACIES WHATSOEVER IN THE DATA OR
                INFORMATION PROVIDED BY YOU OR ANY THIRD PARTY THROUGH THE
                SERVICE, OR ARISING FROM YOUR USE OF THE SERVICE. WITHOUT
                LIMITATION TO THE FOREGOING, DEMAND IQ PROVIDES NO WARRANTY OR
                UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE
                SERVICE OR CONTENT WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY
                INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER
                SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT
                INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR
                BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE
                CORRECTED, NOR DOES IT MAKE ANY REPRESENTATION OR WARRANTY AS TO
                THE RESULTS THAT MAY BE OBTAINED FROM YOUR USE OF THE SERVICE,
                AND USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK.. YOU HEREBY
                IRREVOCABLY WAIVE ANY AND ALL CLAIMS BASED ON OR RELATED TO THE
                FOREGOING. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR
                LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE
                APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE
                ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH
                JURISDICTIONS, YOU ACKNOWLEDGE AND AGREE THAT THIS LIMITATION OF
                LIABILITY SHALL BE ENFORCED TO THE FULLEST EXTENT PERMITTED BY
                LAW.
              </li>
            </ol>
            <ol start="11" role="list">
              <li>
                <strong>Limitation of Liability</strong>. TO THE FULLEST EXTENT
                PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL DEMAND IQ OR ITS
                AFFILIATES, CUSTOMERS, OR ANY OF ITS OR THEIR RESPECTIVE
                LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY: (A) ARISING
                FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE SERVICE
                OR THE CONTENT FOR ANY REASON, INCLUDING BUT NOT LIMITED TO
                PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF
                SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL,
                BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY
                OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL,
                OR PUNITIVE DAMAGES; OR (B) IN EXCESS OF ONE HUNDRED DOLLARS
                ($100) IN THE AGGREGATE. THE FOREGOING LIMITATION WILL APPLY
                WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT
                (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER
                SUCH DAMAGES WERE FORESEEABLE OR DEMAND IQ WAS ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW
                CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE
                LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. IN SUCH
                JURISDICTIONS, YOU ACKNOWLEDGE AND AGREE THAT THIS LIMITATION OF
                LIABILITY SHALL BE ENFORCED TO THE FULLEST EXTENT PERMITTED BY
                LAW.&nbsp;
              </li>
            </ol>
            <ol start="12" role="list">
              <li>
                <strong>Indemnification</strong>. You agree to indemnify,
                defend, and hold harmless Demand IQ and its customers and its
                and their officers, directors, employees, agents, affiliates,
                successors, and assigns from and against any and all losses,
                damages, liabilities, deficiencies, claims, actions, judgments,
                settlements, interest, awards, penalties, fines, costs, or
                expenses of whatever kind, including reasonable attorneys' fees,
                arising from or relating to: (a) your use or misuse of the
                Service; (b) your breach of these User Terms; (c) any
                information you submit or make available through the Service;
                and (d) your violation of any applicable law.
              </li>
            </ol>
            <ol start="13" role="list">
              <li>
                <strong>Severability</strong>. If any provision of these User
                Terms is illegal or unenforceable under applicable law, the
                remainder of the provision will be amended to achieve as closely
                as possible the effect of the original term and all other
                provisions of these User Terms will continue in full force and
                effect.
              </li>
            </ol>
            <ol start="14" role="list">
              <li>
                <strong>Governing Law</strong>. These User Terms are governed by
                and construed in accordance with the internal laws of the State
                of Colorado without giving effect to any choice or conflict of
                law provision or rule. Any legal suit, action, or proceeding
                arising out of or related to these User Terms or the Service
                shall be instituted exclusively in the federal courts of the
                United States or the courts of the State of Colorado in each
                case located in the city and county of Denver. You hereby
                irrevocably waive any and all objections to the exercise of
                jurisdiction over you by such courts and to venue in such
                courts.
              </li>
            </ol>
            <ol start="15" role="list">
              <li>
                <strong>Limitation of Time to File Claims</strong>. ANY CAUSE OF
                ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE
                USER TERMS OR THE SERVICE MUST BE COMMENCED WITHIN ONE (1) YEAR
                AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION
                OR CLAIM IS PERMANENTLY BARRED.
              </li>
            </ol>
            <ol start="16" role="list">
              <li>
                <strong>Third Party Platforms.</strong> Demand IQ may provide
                the Service to you through third-party websites, operating
                systems, platforms, and portals (collectively, “Third Party
                Platforms”). Additional terms and conditions may apply to you
                with respect to your use of Third Party Platforms, which are not
                under Demand IQ’s control. Demand IQ does not assume any
                responsibility or liability for your use of such Third Party
                Platforms.
              </li>
              <li>
                <strong>Notice Regarding Apple.</strong> If you use the
                Application on an Apple, Inc. (“Apple”) iOS-powered mobile
                device, you and Demand IQ acknowledge that these Terms of Use
                are concluded between you and Demand IQ only, and not with
                Apple, and Apple is not responsible for the Application. Your
                license to use the Application is limited to the Apple iOS
                Mobile Device that you own or control and that your use of the
                Application shall be subject to the usage rules set forth in
                Apple’s then-current App Store Terms of Service.
              </li>
              <li>
                <strong>U.S. Export Controls.</strong> The Application may be
                subject to United States export laws, including the including
                the United States Export Administration Act and its associated
                regulations. You shall not, directly or indirectly, export,
                re-export, or release the Application to, or make the
                Application accessible from, any jurisdiction or country to
                which export, re-export, or release is prohibited by law, rule,
                or regulation. You shall comply with all applicable laws, rules,
                and regulations, and complete all required undertakings
                (including obtaining any necessary export license or other
                governmental approval), prior to exporting, re-exporting,
                releasing or otherwise making the Application available outside
                the United States.
              </li>
              <li>
                <strong>Waiver</strong>. No failure to exercise, and no delay in
                exercising, on the part of either party, any right or any power
                hereunder shall operate as a waiver thereof, nor shall any
                single or partial exercise of any right or power hereunder
                preclude further exercise of that or any other right hereunder.
                In the event of a conflict between these User Terms and any
                applicable purchase or other terms, the terms of these User
                Terms shall govern your use of the Service; provided that Demand
                IQ customers and their employees and representatives shall enter
                into a separate agreement to govern customer use of any Demand
                IQ services.
              </li>
              <li>
                <strong>Entire Agreement</strong>. These User Terms constitute
                the entire agreement between you and Demand IQ with respect to
                your use of the Service.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
