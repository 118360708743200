/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import VisibilitySensor from 'react-visibility-sensor';
import { Helmet } from 'react-helmet';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import Chat from 'components/Chat';
import EnvironmentItem from 'components/EnvironmentItem';
import FixedBottomBar from 'components/FixedBottomBar';
import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip';
import SavingChart from 'components/SavingChart';
import Expand from 'components/Expand';
import SunroofImage from 'components/SunroofImage';
import SunroofInfo from 'components/SunroofInfo';
import { ReactComponent as CarbonDioxideIcon } from 'assets/images/icon-carbon-dioxide.svg';
import { ReactComponent as IconDoubleArrowDown } from 'assets/images/icon-double-arrow-down.svg';
import { ReactComponent as LeafIcon } from 'assets/images/icon-leaf.svg';
import { ReactComponent as StationIcon } from 'assets/images/icon-station.svg';
import { ReactComponent as TooltipIcon } from 'assets/images/icon-tooltip.svg';
import iconGoldStar from 'assets/images/icon-gold-star.svg';
import { ReactComponent as GiftIcon } from 'assets/images/icon-gift.svg';
import goldenBell from 'assets/images/img-golden-bell.png';
import {
  calculateData,
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
  trackFacebookPixel,
  trackGoogleLead,
} from 'store/actions';
import {
  engineSolarFindBuilding,
  getExitIntent,
  createExitIntent,
} from 'common/api.mock';
import {
  formatNumber,
  checkIfSunroofIsLoaded,
  generateSolarMaps,
  generateID,
} from 'common/utils';
import { prevLocation } from 'common/history';
import SolarMap from 'components/SolarMap';
import GoogleMapContainer, {
  MapContainer,
} from 'components/GoogleMapContainer';
import { MarvinImage } from '@rarebearsoft/marvin';
import { parseInt, once } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import { Marker } from 'google-maps-react';
import StyledResult3, {
  CircleText,
  Overlay,
  ModalImage,
  ModalTitle,
  ModalContent,
  ModalContentText,
} from './styled';

const ZOOM = 12;

class Result3SolUpComplianceVariation extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');
    this.state = {
      showDialog: false,
      loading: true,
      squareFeet: null,
      sunshineHours: null,
      centerLat: null,
      centerLng: null,
      error: null,
      isScrolled: false,
      solarCount: 0,
      zoom: ZOOM,
      existingSolarCount: 0,
      variation: VARIATION_OPTIONS[variation],
      openIntent: false,
      alreadyIntended: false,
    };

    this.divRef = React.createRef();
    this.handleShowDialog = once(() => {
      this.setState({ showDialog: true });
    });

    const { actions, user } = props;

    actions.trackFacebookPixel('result3', user.variation);
    actions.trackGoogleLead('result3', user.variation);
  }

  componentDidMount() {
    this.initData();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('mouseleave', this.handleMouseLeave);

    (() => {
      const a = String(Math.random()) * 10000000000000;

      new Image().src = `https://pubads.g.doubleclick.net/activity;xsp=4715391;ord=${a}?`;
    })();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mouseleave', this.handleMouseLeave);
  }

  handleMouseLeave = () => {
    const { alreadyIntended } = this.state;

    if (!alreadyIntended) {
      this.setState({ openIntent: true });
    }
  };

  handleLeaveOption = (option) => async () => {
    const { user } = this.props;

    await createExitIntent({
      option,
      is_instant_estimate: user.is_instant_estimate,
    });
    this.setState({ alreadyIntended: true, openIntent: false });
  };

  initData = async () => {
    const { theme, user, actions } = this.props;

    if (user.is_instant_estimate) {
      if (!user.avg_bill || user.avg_bill <= 0) {
        await actions.navigateTo('/calculate2');
      }
      await actions.calculateData();
    }

    const { alreadyIntended } = await getExitIntent();
    if (alreadyIntended === 1) {
      this.setState({ alreadyIntended: true });
    }

    if (!theme.is_sunroof_enabled) {
      this.setState({ loading: false, error: 'Skip sunroof' });
      return;
    }
    const { data: building } = await engineSolarFindBuilding(
      { latitude: user.rooftop_latitude, longitude: user.rooftop_longitude },
      theme.google_api_gateway_key
    );
    if (!building) {
      this.setState({
        loading: false,
        error: 'Requested location is not supported by Google Sunroof API.',
      });
    } else {
      const squareFeet = formatNumber(
        Math.round(
          get(building, 'solarPotential.maxArrayAreaMeters2', 0) * 10.764
        )
      );
      const centerLat = get(building, 'center.latitude', 0);
      const centerLng = get(building, 'center.longitude', 0);
      const sunshineHours = formatNumber(
        Math.round(get(building, 'solarPotential.maxSunshineHoursPerYear', 0))
      );
      await checkIfSunroofIsLoaded(centerLat, centerLng);
      this.setState({
        loading: false,
        squareFeet,
        centerLat,
        centerLng,
        sunshineHours,
      });
      if (centerLat && centerLng) this.countSoloar();
    }
  };

  handleCloseDialog = () => {
    this.setState({ showDialog: false });
  };

  handleNext = async () => {
    this.setState({ showDialog: false });
    const { actions } = this.props;
    actions.setUserData({
      step: '/qualify/start',
      terms_consent: 1,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/qualify/start');
  };

  scrollToBottom = () => {
    this.divRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  handleScroll = (e) => {
    const { isScrolled } = this.state;
    if (isScrolled) return;
    if (e.target.scrollTop > 0 || e.target === window.document) {
      this.setState({ isScrolled: true });
    }
  };

  handleChangeVisible = (isVisible) => {
    if (isVisible) {
      setTimeout(() => {
        this.handleShowDialog();
      }, 1500);
    }
  };

  imageLoaded = (image, index, delX, delY) => {
    const deltaX = delX * image.getWidth();
    const deltaY = delY * image.getHeight();
    let count = 0;
    let startX = 0;
    let startY = 0;
    let endX = 0;
    let endY = 0;
    switch (index) {
      case 0:
        startX = image.getWidth() / 2 + deltaX;
        startY = image.getHeight() / 2 + deltaY;
        endX = image.getWidth();
        endY = image.getHeight();
        break;
      case 1:
        startX = 0;
        startY = image.getHeight() / 2 + deltaY;
        endX = image.getWidth() / 2 + deltaX;
        endY = image.getHeight();
        break;
      case 2:
        startX = image.getWidth() / 2 + deltaX;
        startY = 0;
        endX = image.getWidth();
        endY = image.getHeight() / 2 + deltaY;
        break;
      default:
        startX = 0;
        startY = 0;
        endX = image.getWidth() / 2 + deltaX;
        endY = image.getHeight() / 2 + deltaY;
        break;
    }

    for (let y = parseInt(startY); y < parseInt(endY); y += 3) {
      for (let x = parseInt(startX); x < parseInt(endX); x += 3) {
        const alpha = image.getAlphaComponent(x, y);
        if (alpha !== 0) {
          count++;
        }
      }
    }
    this.setState((prevState) => {
      return {
        solarCount: prevState.solarCount + count,
        existingSolarCount: prevState.existingSolarCount + 1,
      };
    });
  };

  countSoloar = async () => {
    const { centerLat, centerLng, zoom } = this.state;
    const image = [[], [], [], []];

    const proxyurl = 'https://cors-anywhere.demand-iq.com/?';
    const { images, deltaX, deltaY } = generateSolarMaps(
      centerLat,
      centerLng,
      zoom
    );
    images.map((imageUrl, index) => {
      image[index] = new MarvinImage();
      image[index].load(`${proxyurl}${imageUrl}`, () =>
        this.imageLoaded(image[index], index, deltaX, deltaY)
      );
      return false;
    });
  };

  render() {
    const { user, theme, saving, t } = this.props;
    const {
      showDialog,
      loading,
      sunshineHours,
      centerLat,
      centerLng,
      squareFeet,
      error,
      isScrolled,
      solarCount,
      zoom,
      existingSolarCount,
      variation,
      openIntent,
    } = this.state;
    const data = { ...user };
    const projectSunroof =
      !loading &&
      !error &&
      centerLat &&
      centerLng &&
      (squareFeet || sunshineHours);
    const { city, rooftop_latitude, rooftop_longitude, is_instant_estimate } =
      data;
    const { disclosure } = theme;
    let centerPosition = {
      lat: centerLat,
      lng: centerLng,
    };
    if (!centerLat || !centerLng)
      centerPosition = { lat: rooftop_latitude, lng: rooftop_longitude };

    const {
      location: { pathname },
    } = this.props;

    if (is_instant_estimate && loading && !prevLocation) {
      return <Loading />;
    }

    return (
      <>
        <Helmet>
          <script
            type="text/javascript"
            src="//storage.googleapis.com/instapage-assets/conversion-external.js"
          ></script>
        </Helmet>
        <variation.header />

        <div className="main-container" onMouseLeave={this.handleMouseLeave}>
          <div
            className="main"
            style={{
              height: !loading ? 'initial' : null,
              position: 'relative',
            }}
            onScroll={this.handleScroll}
          >
            {loading ? (
              <div className="content">
                <div className="form">
                  <div className="form-content">
                    <Chat
                      id={generateID('div-loading', pathname)}
                      mode="loading"
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <StyledResult3 className="content result-3-page">
                <div className="form">
                  <div className="white-section text-center">
                    <div className="section-container">
                      <div
                        id={generateID('div-avatar', pathname)}
                        className="mb-16"
                      >
                        <Avatar size={78} />
                      </div>

                      <div
                        id={generateID('div-introduction', pathname)}
                        className="mb-16 introduction"
                      >
                        {t(
                          "Here's what going solar in {{city}}, {{state}} could look like for someone with a ${{avg_bill}} average utility bill...",
                          {
                            city: data.city,
                            state: data.state,
                            avg_bill: formatNumber(data.avg_bill),
                          }
                        )}
                      </div>

                      {projectSunroof ? (
                        <div
                          id={generateID('div-sunroof', pathname)}
                          className="sunroof-container mt-32"
                        >
                          {centerLat && centerLng && (
                            <div className="image-container">
                              <SunroofImage lat={centerLat} lng={centerLng} />
                            </div>
                          )}
                          {(sunshineHours || squareFeet) && (
                            <SunroofInfo
                              sunshineHours={sunshineHours}
                              squareFeet={squareFeet}
                              className="mt-16"
                            />
                          )}
                        </div>
                      ) : (
                        <div
                          id={generateID('div-google-map', pathname)}
                          className="google-map-container no-sunroof-container mt-32"
                        >
                          <div
                            className="google-map-inner-container"
                            style={{ height: '15rem' }}
                          >
                            {window.google ? (
                              <MapContainer
                                lat={centerPosition.lat}
                                lng={centerPosition.lng}
                                zoom={20}
                                mapStyles={{
                                  width: '100%',
                                  height: '100%',
                                  position: 'relative',
                                }}
                                saturation={false}
                                google={window.google}
                                draggable={false}
                                mapType="SATELLITE"
                              >
                                <Marker
                                  position={{
                                    lat: centerPosition.lat,
                                    lng: centerPosition.lng,
                                  }}
                                />
                              </MapContainer>
                            ) : (
                              <GoogleMapContainer
                                lat={centerPosition.lat}
                                lng={centerPosition.lng}
                                zoom={20}
                                mapStyles={{
                                  width: '100%',
                                  height: '100%',
                                }}
                                saturation={false}
                                draggable={false}
                                mapType="SATELLITE"
                              >
                                <Marker
                                  position={{
                                    lat: centerPosition.lat,
                                    lng: centerPosition.lng,
                                  }}
                                />
                              </GoogleMapContainer>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    id={generateID('div-solar-map', pathname)}
                    style={{
                      display:
                        solarCount < 15 || existingSolarCount < 4
                          ? 'none'
                          : 'block',
                    }}
                  >
                    {projectSunroof && centerLat && centerLng && (
                      <div className="google-map-wrapper">
                        <div className="map-container">
                          {window.google ? (
                            <MapContainer
                              lat={centerLat}
                              lng={centerLng}
                              zoom={12}
                              mapStyles={{
                                width: '100%',
                                height: 'auto',
                                opacity: 0.35,
                                paddingTop: '-30px',
                              }}
                              google={window.google}
                            />
                          ) : (
                            <GoogleMapContainer
                              lat={centerLat}
                              lng={centerLng}
                              zoom={12}
                              mapStyles={{
                                width: '100%',
                                height: 'auto',
                                opacity: 0.35,
                                paddingTop: '-30px',
                              }}
                            />
                          )}
                          <SolarMap
                            lat={centerLat}
                            lng={centerLng}
                            zoom={zoom}
                          />
                        </div>
                        <div className="map-description">
                          <div className="section-header">
                            <div className="title">{t('Did you know?')}</div>
                            <div className="gone-soloar">
                              <Trans
                                t={t}
                                i18nKey="Over <1> {{solarCount}} homes </1> near {{city}} have gone solar!"
                              >
                                Over <span> {{ solarCount }} homes </span>
                                near {{ city }} have gone solar!
                              </Trans>
                            </div>
                          </div>
                          <div className="section-content">
                            <div className="icon-mark">
                              <img
                                src={iconGoldStar}
                                alt="icon-gold-star"
                                style={{
                                  height: 18,
                                  marginRight: 5,
                                  verticalAlign: 'center',
                                }}
                              />
                              {t('Your Zip Code')}
                            </div>
                            <div className="icon-mark">
                              <svg
                                width="15"
                                height="15"
                                style={{
                                  verticalAlign: 'center',
                                  marginTop: -2,
                                  marginRight: 5,
                                }}
                              >
                                <rect
                                  width="15"
                                  height="15"
                                  style={{
                                    fill: '#f00',
                                    strokeWidth: 10,
                                  }}
                                />
                              </svg>
                              {t('Solar Install')}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <VisibilitySensor
                    onChange={this.handleChangeVisible}
                    minTopValue={300}
                  >
                    <div
                      id={generateID('div-monthly-payment', pathname)}
                      className="dark-section text-center"
                      ref={this.divRef}
                    >
                      <div className="section-container">
                        <div className="section-title-variation mb-16">
                          {t(
                            'Replace or reduce your utility bill with solar for just...'
                          )}
                          <sub>1</sub>
                        </div>
                        <div className="mb-16">
                          <CircleText>
                            <div className="circle-text-main">
                              {theme.price_system_display ||
                              !theme.is_premier_account ? (
                                <span>
                                  $
                                  {formatNumber(
                                    Math.round(data.monthly_payment_min)
                                  )}
                                  -$
                                  {formatNumber(
                                    Math.round(data.monthly_payment_max)
                                  )}
                                </span>
                              ) : data.monthly_payment_best_guess ? (
                                <span className="exact-pricing-main-number">
                                  $
                                  {formatNumber(
                                    Math.round(data.monthly_payment_best_guess)
                                  )}
                                </span>
                              ) : (
                                <span>
                                  $
                                  {formatNumber(
                                    Math.round(data.monthly_payment_min)
                                  )}
                                  -$
                                  {formatNumber(
                                    Math.round(data.monthly_payment_max)
                                  )}
                                </span>
                              )}
                            </div>
                            <div
                              className={`circle-text-small ${
                                theme.price_system_display ||
                                !theme.is_premier_account
                                  ? ''
                                  : 'exact-pricing-main-number'
                              }`}
                            >
                              {t('per month')}
                              <sub>2</sub>
                            </div>
                          </CircleText>
                        </div>
                        <div className="bold-text">
                          <sup>1. </sup>
                          Estimate only, based on average utility bill; actual
                          monthly savings may vary.
                          <br />
                          <sup>2.</sup>
                          {t(
                            ' {{product_apr}}% APR financing for {{product_term}} years on approved credit with zero down.',
                            {
                              product_apr: theme.product_apr,
                              product_term: theme.product_term,
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </VisibilitySensor>

                  <div
                    id={generateID('div-total-incentive', pathname)}
                    className="black-section"
                  >
                    <div className="section-container">
                      <div className="discount-block">
                        <div className="bg-circle-icon">
                          <GiftIcon />
                        </div>
                        <div className="discount-info">
                          <div className="discount-title">
                            {t('Total Incentive Discounts')}
                          </div>
                          <div className="discount-value">
                            ${`${formatNumber(data.incentive)}`}
                            <sup>3</sup>
                          </div>
                          <div className="divider">
                            <span className="divider-text">
                              {t('Only while available!')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          color: '#fff',
                          textAlign: 'center',
                          paddingTop: '15px',
                        }}
                      >
                        <sup>3.</sup>Discount based on estimated system size.
                        actual amount may vary.
                      </div>
                    </div>
                  </div>

                  <div
                    id={generateID('div-25-year-savings', pathname)}
                    className="dark-section text-center"
                  >
                    <div className="section-container">
                      <div className="section-title text-normal">
                        {t('Estimated 25 Year Savings')}
                      </div>
                      <div className="section-description mb-32">
                        ${`${formatNumber(data.savings)}`}
                      </div>
                      <SavingChart
                        value1={data.avg_bill}
                        value2={formatNumber(
                          Math.round(data.avg_bill * 1.03 ** 10)
                        )}
                        value3={formatNumber(
                          Math.round(data.avg_bill * 1.03 ** 25)
                        )}
                      />
                    </div>
                  </div>

                  <div
                    id={generateID('div-utility-rates', pathname)}
                    className="expand-section dark"
                  >
                    <div className="section-container">
                      <Expand
                        title={t('Why do utility rates go up?')}
                        isExpanded
                      >
                        {t(
                          'Utility rates in this market have historically increased by {{escalation}}% each year. This is based on a variety of market factors including inflation. The foregoing estimates are theoretical and best estimates; the actual cost of electricity may vary or decrease over time.',
                          {
                            escalation: theme.escalation || 3,
                          }
                        )}
                        <br />
                        <br />
                        {t(
                          'Nevada Energy - Northern Average Rate/kWh: ${{rate}}',
                          {
                            rate: 0.12654,
                          }
                        )}
                        <br />
                        {t(
                          'Nevada Energy - Southern Average Rate/kWh: ${{rate}}',
                          {
                            rate: 0.15864,
                          }
                        )}
                        <br />
                        {t(
                          'Valley Electric Association Average Rate/kWh: ${{rate}}',
                          {
                            rate: 0.145,
                          }
                        )}
                        <br />
                        {t('Boulder City Utility Average Rate/kWh: ${{rate}}', {
                          rate: 0.1187,
                        })}
                        <br />
                        <br />
                        {t('All rates as of {{date}}.', {
                          date: '11-14-2023',
                        })}
                      </Expand>
                    </div>
                  </div>

                  <div
                    id={generateID('div-home-value', pathname)}
                    className="white-section text-center"
                  >
                    <div className="section-container">
                      <div className="section-title">
                        {t('Home Value Increase')}
                      </div>
                      <div className="section-description">4.1%</div>
                      <div className="subtext">
                        {t('According to a recent study from Zillow.')}
                      </div>
                    </div>
                  </div>

                  <div
                    id={generateID('div-zillow-study', pathname)}
                    className="expand-section"
                  >
                    <div className="section-container">
                      <Expand title={t('Learn about the Zillow study.')}>
                        {t(
                          'Zillow calculated the solar premium by comparing homes with and without solar-energy systems that were listed for sale and sold from March 1, 2018 to February 28, 2019, controlling for observable attributes of the homes.'
                        )}
                      </Expand>
                    </div>
                  </div>

                  <div
                    id={generateID('div-environmental-impact', pathname)}
                    className="dark-section text-center"
                  >
                    <div className="section-container">
                      <div className="section-title">
                        {t('Estimated Environmental Impact')}
                        <sub>5</sub>
                      </div>
                      <div className="environmental-list">
                        <EnvironmentItem
                          icon={<CarbonDioxideIcon />}
                          title={`${data.co2_tons}`}
                          content={t('Tons of CO2 avoided')}
                        />
                        <EnvironmentItem title="=" />
                        <EnvironmentItem
                          icon={<LeafIcon />}
                          title={`${data.tree_seedlings}`}
                          content={t('Trees grown for 10 years')}
                        />
                        <EnvironmentItem title="=" />
                        <EnvironmentItem
                          icon={<StationIcon />}
                          title={`${data.gal_of_gas}`}
                          content={t('Gallons of gas not used')}
                        />
                      </div>
                    </div>
                    <br />
                    <span>
                      <sup>5</sup>Estimated Environmental Impact based on the
                      EPA’s Greenhouse Gases Equivalencies Calculator.
                    </span>
                  </div>

                  <div
                    id={generateID('div-system-size', pathname)}
                    className="white-section text-center"
                  >
                    <div className="section-container">
                      <div className="section-title">{t('System Size')}</div>
                      <div
                        className="section-description"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {theme.price_system_display ||
                        !theme.is_premier_account ? (
                          `${data.install_size_min}-${data.install_size_max}`
                        ) : (
                          <span className="exact-system-size-number">
                            {data.install_size}
                          </span>
                        )}
                        <small>kW</small>{' '}
                        <TooltipIcon data-for="tooltip" data-tip />
                        <Tooltip id="tooltip" place="left">
                          <div className="tooltip-content">
                            {t('{{panels_min}}-{{panels_max}} Panels', {
                              panels_min: data.panels_min,
                              panels_max: data.panels_max,
                            })}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </StyledResult3>
            )}

            {!loading && (
              <Overlay
                id={generateID('div-overlay', pathname)}
                className={isScrolled && 'hide'}
              >
                <div className="scroll-wrapper">
                  <div className="header">{t('SCROLL TO SEE MORE')}</div>
                  <IconDoubleArrowDown />
                </div>
              </Overlay>
            )}

            {showDialog && (
              <Modal
                id={generateID('modal-ballpark-estimate', pathname)}
                onBackgroundClick={this.handleCloseDialog}
                isOpen={showDialog}
                className="px-24 mt-0"
                style={{ borderRadius: 23 }}
              >
                <ModalContent>
                  <ModalImage>
                    <img
                      src={goldenBell}
                      alt="golden-bell"
                      style={{ width: 100, height: 100 }}
                    />
                  </ModalImage>

                  <ModalTitle>{t('This is a ballpark estimate')}</ModalTitle>

                  <ModalContentText>
                    {t(
                      'To receive a finalized quote, your home will need to be reviewed by an expert, and we will need copies of your electric bill.'
                    )}
                  </ModalContentText>

                  <Button
                    id={generateID(
                      'btn-back-to-estimate',
                      `${pathname}/modal-ballpark-estimate`
                    )}
                    full
                    onClick={this.handleCloseDialog}
                  >
                    {t('Back to estimate')}
                  </Button>
                </ModalContent>
              </Modal>
            )}

            {openIntent && (
              <Modal
                id={generateID('modal-exit-intent', pathname)}
                showCloseIcon
                isOpen={openIntent}
                onClose={this.handleLeaveOption('no_answer')}
                onBackgroundClick={this.handleLeaveOption('no_answer')}
              >
                <ModalImage>
                  <img
                    src={goldenBell}
                    alt="golden-bell"
                    style={{ width: 100, height: 100 }}
                  />
                </ModalImage>

                <ModalTitle>{t('Why are you leaving?')}</ModalTitle>

                <div className="modal-content">
                  <div className="mb-24">
                    <Button
                      id={generateID(
                        'btn-cost-is-too-high',
                        `${pathname}/modal-exit-intent`
                      )}
                      full
                      onClick={this.handleLeaveOption('cost')}
                      outline
                    >
                      {t('Cost is too high')}
                    </Button>
                  </div>

                  <div className="mb-24">
                    <Button
                      id={generateID(
                        'btn-savings-are-too-low',
                        `${pathname}/modal-exit-intent`
                      )}
                      full
                      onClick={this.handleLeaveOption('savings')}
                      outline
                    >
                      {t('Savings are too low')}
                    </Button>
                  </div>

                  <div className="mb-48">
                    <Button
                      id={generateID(
                        'btn-i-will-come-back-later',
                        `${pathname}/modal-exit-intent`
                      )}
                      full
                      onClick={this.handleLeaveOption('later')}
                      outline
                    >
                      {t("I'll come back later")}
                    </Button>
                  </div>

                  <div className="mb-32">
                    <Button
                      id={generateID(
                        'btn-back-to-estimate',
                        `${pathname}/modal-exit-intent`
                      )}
                      full
                      onClick={this.handleLeaveOption('no_answer')}
                    >
                      {t('Back to estimate')}
                    </Button>
                  </div>
                </div>
              </Modal>
            )}

            <variation.footer
              customText={
                !loading && (
                  <div
                    id={generateID('div-legal', pathname)}
                    className="custom-text"
                  >
                    <Trans
                      t={t}
                      i18nKey="DISCLAIMER: <1/> While every effort has been taken to ensure that the calculations are as accurate as possible, the information provided by the calculator trades some accuracy for its simplicity and ease of use. The information is general in nature and is not intended to be a substitute for legal, financial, or electrical engineering advice. Please use the figures provided by the calculator as ballpark figure ONLY. For more accurate financial savings estimates, please reach out to us directly to have a system customized to the roof of your home. <1/> <1/> {{disclosure}} <1/> <1/> **Based on estimated system size, actual amount may vary. <1/> <1/>***No customer may waive their rights to any provision of Senate Bill 303, or the contract will be voidable if they do so. <1/> <1/>Customer may contact the Nevada State Contractors board for clarification of any contract and shall have the right to request a bond and performance, if such bond is not otherwise required pursuant to NRS 624.270. <1/> <1/>Nevada License C-2 #75025. Bid Limit $800,000."
                    >
                      DISCLAIMER: <br />
                      While every effort has been taken to ensure that the
                      calculations are as accurate as possible, the information
                      provided by the calculator trades some accuracy for its
                      simplicity and ease of use. The information is general in
                      nature and is not intended to be a substitute for legal,
                      financial, or electrical engineering advice. Please use
                      the figures provided by the calculator as ballpark figure
                      ONLY. For more accurate financial savings estimates,
                      please reach out to us directly to have a system
                      customized to the roof of your home.
                      <br /> <br />
                      {{ disclosure }}
                      <br /> <br />
                      **Based on estimated system size, actual amount may vary.
                      <br /> <br />
                      ***No customer may waive their rights to any provision of
                      Senate Bill 303, or the contract will be voidable if they
                      do so.
                      <br /> <br />
                      Customer may contact the Nevada State Contractors board
                      for clarification of any contract and shall have the right
                      to request a bond and performance, if such bond is not
                      otherwise required pursuant to NRS 624.270.
                      <br /> <br />
                      Nevada License C-2 #75025. Bid Limit $800,000.
                    </Trans>
                  </div>
                )
              }
            />

            {!loading && isScrolled && (
              <FixedBottomBar id={generateID('div-fixed-bottom-bar', pathname)}>
                <Button
                  id={generateID('btn-get-a-finalized-quote', pathname)}
                  full
                  onClick={this.handleNext}
                  className="pulse-button"
                  disabled={saving}
                >
                  {t('Get a Finalized Quote')}
                </Button>
              </FixedBottomBar>
            )}
          </div>
        </div>

        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
              <iframe
              title="instapage-code"
              class="instapage-noscrap"
              src="//d3mwhxgzltpnyp.cloudfront.net/local-storage/index.html"
              name="instapage-local-storage"
              height="0px"
              width="0px"
              onLoad="(function() { InstapageExternalConversion(${theme.instapage_id}); })();"
              style="display: block;"
            ></iframe>
          `,
          }}
        ></div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui: { theme } }) => ({
  user: data,
  theme,
  saving,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      calculateData,
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
      trackFacebookPixel,
      trackGoogleLead,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Result3SolUpComplianceVariation)
);
