import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import TextInput from 'components/Forms/TextInput';
import Checkbox from 'components/Forms/CheckboxInput';
import Chat from 'components/Chat';
import Tooltip from 'components/Tooltip';
import ProgressBar from 'components/ProgressBar';
import { ReactComponent as TooltipIcon } from 'assets/images/icon-tooltip.svg';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import {
  verifyPhoneNumber,
  verifyEmail,
  sendVerificationCode,
  lookupPhoneNumber,
} from 'common/api.mock';
import { generateID, getNames } from 'common/utils';
import { withTranslation } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import InputMask from 'react-input-mask';
import {
  Typography,
  InputAdornment,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import {
  setUserData,
  saveSession,
  trackFacebookPixel,
  navigateTo,
  trackForwardProgress,
} from '../../../store/actions';
import { Result2 as StyledResult2, Legal } from './styled';

const StyledLabel = withStyles({
  label: {
    fontSize: '10px',
    lineHeight: '12px',
    color: '#666666',
  },
})(FormControlLabel);

const formSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  // .matches(/^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$/),
  email: Yup.string().strict(false).trim().email().required('Required'),
  phone: Yup.string().strict(false).trim().required('Required'),
  optIn: Yup.boolean().strict(false),
});

const CustomInput = ({ ...props }) => (
  <InputMask {...props}>{() => <TextInput {...props} />}</InputMask>
);

class Result2 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');
    this.state = {
      loading: false,
      loaded: false,
      errors: null,
      jornaya_lead_id: window.jornaya_leadid_token,
      variation: VARIATION_OPTIONS[variation],
      chatLoaded: false,
    };
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/result1');
  };

  handleNext = async (values, { setFieldError, setValues }) => {
    // eslint-disable-next-line prefer-const
    let { phone, email, name, optIn } = values;
    const { actions, user, theme, t } = this.props;
    const {
      is_phone_verification_required,
      is_strict_phone_number_validation_enabled,
      is_strict_email_validation_enabled,
    } = theme;

    // trim phone, email before submitting
    phone = phone.trim();
    email = email.trim();
    name = name.trim();
    setValues({ phone, email, name, optIn });

    const { first: first_name, last: last_name } = getNames(name);

    const { jornaya_lead_id } = this.state;
    this.setState({ loading: true, errors: null });

    let phoneRes;
    let emailRes;
    let lookupRes;

    if (is_strict_phone_number_validation_enabled) {
      phoneRes = await verifyPhoneNumber(phone);
      lookupRes = await lookupPhoneNumber({ phone });
    }
    if (is_strict_email_validation_enabled) {
      emailRes = await verifyEmail(email);
    }
    const errors = [];

    if (!first_name) {
      errors.push(t('Please provide your full name.'));
      setFieldError('name', 'Invalid');
    }
    if (!last_name) {
      errors.push(t('Please provide your last name.'));
      setFieldError('name', 'Invalid');
    }

    if (
      is_strict_phone_number_validation_enabled &&
      !phoneRes?.success &&
      !lookupRes
    ) {
      errors.push(
        t('That number does not seem to be a valid mobile phone number.')
      );
      setFieldError('phone', 'Invalid');
    }
    if (is_strict_email_validation_enabled && !emailRes?.success) {
      errors.push(t('That email does not seem to be a valid email.'));
      setFieldError('email', 'Invalid');
    }
    if (errors.length > 0) {
      this.setState({ loading: false, loaded: true, errors });
    } else {
      actions.setUserData({
        first_name,
        last_name,
        email,
        opt_in_messaging: optIn,
        phone: phoneRes ? phoneRes.data.national_format : phone,
        jornaya_lead_id,
        trustedform_cert_url: window.trustedForm.id
          ? `https://cert.trustedform.com/${window.trustedForm.id}`
          : '',
        step: is_phone_verification_required ? '/phoneverify' : '/result3',
      });

      actions.trackForwardProgress();
      await actions.saveSession();
      if (window.heap) {
        window.heap.identify(email);
      }
      const { user: newUser } = this.props;

      if (newUser.is_disqualified) {
        actions.setUserData({
          step: '/result2',
        });
        await actions.saveSession();
        await actions.navigateTo('/disqualify');
      } else {
        const { is_phone_verified } = user;

        if (is_phone_verification_required && !is_phone_verified) {
          const { detail } = await sendVerificationCode({ phone });
          if (!detail) await actions.navigateTo('/result3');
          else await actions.navigateTo('/phoneverify');
        } else {
          await actions.navigateTo('/result3');
        }
      }
    }
  };

  getFullName = () => {
    const { user } = this.props;
    const name = [];
    if (user.first_name) {
      name.push(user.first_name);
    }
    if (user.last_name) {
      name.push(user.last_name);
    }
    return name.join(' ');
  };

  render() {
    const { user, theme, t } = this.props;
    const { loading, loaded, errors, variation, chatLoaded } = this.state;
    const errorMsg = errors ? errors.join('\n') : '';
    const disclosure = theme?.sunlight?.disclosures?.find(
      ({ type }) => type === 'PhoneNumberSolar'
    );

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledResult2 className="content result-2-page">
              <Formik
                initialValues={{
                  name: this.getFullName() || '',
                  email: user.email || '',
                  phone: user.phone || '',
                  optIn: true,
                }}
                validationSchema={formSchema}
                onSubmit={this.handleNext}
                validateOnMount
              >
                {({ isValid, isSubmitting }) => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <div
                          id={generateID('div-progress-bar', pathname)}
                          className="mb-20 w100"
                        >
                          <ProgressBar value={100} step={4} />
                        </div>

                        <Chat
                          id={generateID('chat-unlock-live-pricing', pathname)}
                          mode={loaded || loading ? 'loading' : 'timeout'}
                          loading={loading}
                          onShow={() => this.setState({ chatLoaded: true })}
                        >
                          <div className="mb-16">
                            {!loaded ? (
                              <span>
                                <span className="bold">
                                  {t(
                                    'Your online estimate is on the next page!'
                                  )}
                                </span>{' '}
                                {t('Now unlock live pricing!')}
                              </span>
                            ) : (
                              errorMsg
                            )}
                            &nbsp;
                            <TooltipIcon data-for="tooltip" data-tip />
                            <Tooltip id="tooltip" place="bottom">
                              <div className="tooltip-title">
                                {t('We do not sell your information.')}
                              </div>
                              <div className="tooltip-content">
                                {t(
                                  'Our online savings estimates are conservative. Typically, we can improve your savings estimate with more info about your project.'
                                )}
                              </div>
                            </Tooltip>
                          </div>
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-name', pathname)}
                              name="name"
                              type="text"
                              placeholder={t('First and Last Name')}
                              theme={theme}
                              component={TextInput}
                              autoFocus
                            />
                          </div>
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-email', pathname)}
                              name="email"
                              type="email"
                              placeholder={t('Email')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                          <div className="mb-16">
                            <Field>
                              {({ field, form }) => (
                                <CustomInput
                                  id={generateID('txt-phone', pathname)}
                                  mask="(999) 999-9999"
                                  name="phone"
                                  type="tel"
                                  placeholder={t('Mobile phone number')}
                                  theme={theme}
                                  value={field.value.phone}
                                  onChange={field.onChange}
                                  field={field}
                                  form={form}
                                  InputProps={{
                                    endAdornment: user.is_sunlight_available ? (
                                      <InputAdornment>
                                        <TooltipIcon
                                          data-tip
                                          data-for="phone-number-tooltip"
                                          color="#ff0000"
                                        />
                                      </InputAdornment>
                                    ) : null,
                                  }}
                                />
                              )}
                            </Field>
                            <Tooltip id="phone-number-tooltip" place="bottom">
                              <div className="tooltip-content">
                                Sunlight will use the provided mobile phone
                                number for account management purposes.
                                <br />
                                If you do not have a mobile device, please enter
                                any valid phone number.
                              </div>
                            </Tooltip>
                          </div>
                          {theme.enable_opt_in ? (
                            <div className="mb-16">
                              <Field>
                                {({ field }) => (
                                  <StyledLabel
                                    onChange={field.onChange}
                                    name="optIn"
                                    control={
                                      <Checkbox
                                        name="optIn"
                                        defaultChecked
                                        value="on"
                                      />
                                    }
                                    label={`By checking this
                                    box, you consent to receive promotional
                                    SMS messages, calls, and emails from
                                    ${theme.company_name} and its affiliated
                                    partners, even if your number is on a
                                    "Do Not Call" list. We will never share your personal information with third parties for marketing purposes.
                                    Consent is not a condition of purchase. Message frequency may vary.
                                    Message and data rates may apply. You can opt-out by
                                    replying "STOP". For help, reply "HELP".`}
                                    style={{
                                      alignItems: 'flex-start',
                                      paddingTop: 5,
                                      paddingLeft: '1rem',
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          ) : null}
                        </Chat>
                        {chatLoaded &&
                          !loading &&
                          !theme.enable_opt_in &&
                          user.is_sunlight_available && (
                            <div className="sunlight-phone-number-solar-disclosure">
                              {disclosure?.disclosureText || ''}
                            </div>
                          )}
                      </div>

                      <div
                        id={generateID('div-actions', pathname)}
                        className="form-actions"
                      >
                        <Button
                          id={generateID('btn-back', pathname)}
                          outline
                          onClick={this.handleBack}
                        >
                          <BackIcon />
                        </Button>
                        <Button
                          id={generateID('btn-unlock-estimate', pathname)}
                          type="submit"
                          full
                          disabled={!isValid || isSubmitting}
                        >
                          {t('Unlock Estimate')}
                          <ArrowRightIcon />
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </StyledResult2>

            {!loading && !theme.enable_opt_in && (
              <Legal
                id={generateID('div-legal', pathname)}
                className="form-legal"
              >
                <label>
                  <input type="hidden" id="leadid_tcpa_disclosure" />
                  {theme.company_name === 'Venture Solar' ? (
                    <>
                      By submitting your contact information through this form,
                      you are providing express written consent to Venture Home
                      Solar, LLC d/b/a Venture Solar d/b/a Venture Home to
                      contact you with customer care messages regarding our
                      products and services at the phone number you provide.
                      This consent includes, but is not limited to, calls, text
                      messages, and prerecorded messages using an automatic
                      telephone dialing system or an artificial or prerecorded
                      voice, even if your number is listed on any state or
                      federal "Do Not Call" list.
                      <br />{' '}
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Important Information:
                      </Typography>
                      <ul>
                        <li>
                          Consent is not required to purchase goods or services
                          from us.
                        </li>
                        <li>
                          You can opt-out at any time by replying "STOP" to any
                          text message.
                        </li>
                        <li>For help, reply "HELP" or contact us directly.</li>
                        <li>Message and data rates may apply.</li>
                      </ul>
                      <>
                        Message frequency varies. For more information on how
                        your information is used, please review our terms and
                        conditions and our{' '}
                        <a
                          style={{ color: '#666666' }}
                          href="https://venturesolar.com/privacy/"
                        >
                          Privacy Policy.
                        </a>
                      </>
                    </>
                  ) : (
                    t(
                      'To help you go solar, we need your approval to contact you. By clicking above, you agree that {{company}} may email and call you about {{company}} products at the phone number you entered above, using pre-recorded messages or an autodialer, even if your number is on a "Do Not Call" list. Consent for calls is optional. You can opt-out anytime.',
                      { company: theme.company_name }
                    )
                  )}
                </label>
              </Legal>
            )}
            <variation.footer showLegal />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Result2)
);
